module.exports = {
    'instagram': {
        name: 'Instagram',
        shortName: 'Instagram',
        color: '#833AB4',
        icon: 'icon-instagram',
        helpWikiURLId: '',
        classNames: {
            list: 'platform-list-instagram',
            listComplete: 'platform-list-instagram-complete'
        },
        publicMessageLimit: 0
    },
    'messenger': {
        name: 'Facebook Messenger',
        shortName: 'Messenger',
        color: '#1787FB',
        icon: 'icon-messenger',
        helpWikiURLId: '156958821',
        classNames: {
            list: 'platform-list-messenger',
            listComplete: 'platform-list-messenger-complete'
        },
        publicMessageLimit: 0
    },
    'telegram': {
        name: 'Telegram',
        shortName: 'Telegram',
        color: '#249DDB',
        icon: 'icon-telegram',
        helpWikiURLId: '157057077',
        classNames: {
            list: 'platform-list-telegram',
            listComplete: 'platform-list-telegram-complete'
        },
        publicMessageLimit: 0
    },
    'webchattonic': {
        name: 'Web Chat-Tonic',
        shortName: 'Webchat',
        color: '#ED8A9D',
        icon: 'icon-webchattonic',
        helpWikiURLId: '157024326',
        classNames: {
            list: 'platform-list-webchattonic',
            listComplete: 'platform-list-webchattonic-complete'
        },
        publicMessageLimit: 0
    },
    'whatsapp-clickatell': {
        name: 'WhatsApp (Clickatell)',
        shortName: 'Clickatell',
        color: '#25E47B',
        icon: 'icon-whatsapp',
        helpWikiURLId: '157188159',
        classNames: {
            list: 'platform-list-whatsapp',
            listComplete: 'platform-list-whatsapp-complete'
        },
        publicMessageLimit: 0
    },
    'whatsapp-gupshup': {
        name: 'WhatsApp (GupShup)',
        shortName: 'GupShup',
        color: '#25E47B',
        icon: 'icon-whatsapp',
        helpWikiURLId: '1679851521',
        classNames: {
            list: 'platform-list-whatsapp',
            listComplete: 'platform-list-whatsapp-complete'
        },
        publicMessageLimit: 0
    },
    'whatsapp-infobip': {
        name: 'WhatsApp (Infobip)',
        shortName: 'Infobip',
        color: '#25E47B',
        icon: 'icon-whatsapp',
        helpWikiURLId: '603750403',
        classNames: {
            list: 'platform-list-whatsapp',
            listComplete: 'platform-list-whatsapp-complete'
        },
        publicMessageLimit: 0
    },
    'whatsapp-meta': {
        name: 'WhatsApp (Meta)',
        shortName: 'Meta',
        color: '#25E47B',
        icon: 'icon-whatsapp',
        helpWikiURLId: '',
        classNames: {
            list: 'platform-list-whatsapp',
            listComplete: 'platform-list-whatsapp-complete'
        },
        publicMessageLimit: 0
    },
    'whatsapp': {
        name: 'WhatsApp',
        shortName: 'WhatsApp',
        color: '#25E47B',
        icon: ['fab', 'whatsapp'],
        helpWikiURLId: null,
        classNames: {
            list: 'platform-list-whatsapp',
            listComplete: 'platform-list-whatsapp-complete'
        },
        publicMessageLimit: 0
    },
    'unknown': {
        name: 'Unknown',
        shortName: 'unknown',
        color: '#FFFFFF',
        icon: 'icon-question',
        helpWikiURLId: null,
        classNames: {
            list: 'platform-list-unknown',
            listComplete: 'platform-list-unknown-complete'
        },
        hidden: true
    },
    publicMessageLimit: 0
}
