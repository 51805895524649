import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-timezone'
import { withLocalize, Translate } from 'react-localize-redux'

import {
    Wrapper,
    Container,
    Message,
    Avatar,
    ExtraData,
    Date,
    Line
} from './styles'
import {
    Button,
    Modal,
    Alert
} from 'react-bootstrap'
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import Data from '../Message'

class MessageContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showWebView: false,
            showModalDelete: false,
            messageDeleted: false
        }
        this.onDelete = this.onDelete.bind(this)
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
        this.toggleWebView = this.toggleWebView.bind(this)
    }

    componentWillMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _setState(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }

    async toggleDeleteModal() {
        await this._setState({
            showModalDelete: !this.state.showModalDelete
        })
    }

    async onDelete() {
        const message = this.props.data
        try {
            if (message) {
                await this.props.deleteMessage(message)
            }
            await this.toggleDeleteModal()
        } catch (e) {
            //
        } finally {
            await this._setState({
                messageDeleted: true
            })
        }
    }

    toggleWebView() {
        this.setState({
            showWebView: !this.state.showWebView
        })
    }

    render() {
        const { data, logo, color, timezone, activeLanguage, checkScrollToBottom, chattonicURL, speech, queryMessageId, queryStatus } = this.props
        const { showModalDelete, messageDeleted } = this.state
        let date = moment.tz(data.createdAt, timezone).locale(activeLanguage.code).format('L LT')
        if (moment.utc(data.createdAt).startOf('day').isSame(moment.utc().startOf('day'))) {
            date = moment.tz(data.createdAt, timezone).locale(activeLanguage.code).format('HH:mm')
        } else if (moment.utc(data.createdAt).startOf('week').isSame(moment.utc().startOf('week'))) {
            date = moment.tz(data.createdAt, timezone).locale(activeLanguage.code).format('dddd HH:mm')
        }
        const isOperator = data && data.operatorData && data.operatorData.name && data.operatorData.name.familyName && data.operatorData.name.givenName
        const allowDeleteMessage = data && data.platform && ['whatsapp-chatapi-instance', 'telegram'].indexOf(data.platform) >= 0
        return (
            <Wrapper lastGroupMessage={data.lastGroupMessage} from={data.from}>
                {(data.to === 'self' || data.firstGroupMessage) ? (
                    <Avatar lastGroupMessage={data.lastGroupMessage} from={data.from} logo={logo} color={color} userColor={data.userColor}>
                        {data.from === 'self' && isOperator && (
                            <div>
                                <div className='displayName'>{`${data.operatorData.name.givenName.charAt(0)} ${data.operatorData.name.familyName}`}</div>
                                <span className='tipOver'>{`${data.operatorData.name.givenName} ${data.operatorData.name.familyName} ${data.operatorData.position ? '| ' + data.operatorData.position : ''}  ${data.operatorData.area ? '| ' + data.operatorData.area : ''}`}</span>
                            </div>
                        )}
                    </Avatar>
                ) : data.operatorData ? (
                    <Line color={data.userColor}></Line>
                ) : ''}
                <Container firstGroupMessage={data.firstGroupMessage} from={data.from} operatorData={data.operatorData}>
                    { !messageDeleted && <Message isText={['text', 'formData'].indexOf(data.type) >= 0} from={data.from} operatorData={data.operatorData} isQueryMessage={data.isPublic && queryMessageId && queryMessageId === data.id} queryStatus={queryStatus}>
                        <Data
                            data={data}
                            showWebView={this.state.showWebView}
                            toggleWebView={this.toggleWebView}
                            checkScrollToBottom={checkScrollToBottom}
                            chattonicURL={chattonicURL}
                            speech={speech}
                            activeLanguageCode={activeLanguage.code}
                        />
                        {allowDeleteMessage && data.from === 'self' && isOperator && moment.utc(data.createdAt).isAfter(moment.utc().subtract(1, 'hours')) && (
                            <div className='tipOver' onClick={this.toggleDeleteModal}>
                                <FontAwesomeIcon icon="trash-alt" /> <Translate id={`query.message.type.delete`} />
                            </div>
                        )}
                    </Message> || <Alert bsStyle='warning' style={{padding: '5px', margin:'0'}}><Translate id={`query.message.delete.success`} /></Alert>}
                    <ExtraData from={data.from}>
                        <Date>{date}</Date>
                    </ExtraData>
                </Container>
                <Modal show={showModalDelete} onHide={this.toggleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title><Translate id={`query.message.delete.title`} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Translate id={`query.message.delete.description`} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" onClick={this.toggleDeleteModal}>
                            <Translate id='buttons.cancel' />
                        </Button>
                        <Button bsStyle="danger" onClick={this.onDelete}>
                            <Translate id='buttons.delete' />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Wrapper>
        )
    }
}

MessageContainer.propTypes = {
    deleteMessage: PropTypes.func,
    data: PropTypes.object,
    logo: PropTypes.string,
    color: PropTypes.string,
    timezone: PropTypes.string,
    checkScrollToBottom: PropTypes.func,
    chattonicURL: PropTypes.string.isRequired,
    speech: PropTypes.func,
    queryMessageId: PropTypes.string,
    queryStatus: PropTypes.string
}

export default withLocalize(MessageContainer)
