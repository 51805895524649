import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import * as project from '../../utils/project'
import { Translate, withLocalize } from 'react-localize-redux'
import { browserHistory } from 'react-router'
import * as platforms from '../common/Platforms'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import * as yup from 'yup'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import {
    Alert,
    Button,
    ButtonToolbar,
    ControlLabel
} from 'react-bootstrap'

import {
    FormGroup,
    Text
} from './styles'

class HSMs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            account: null,
            accounts: null,
            hsmTemplates: null,
            origins: null,
            send: {
                phone: '',
                template: undefined
            },
            origin: '',
            parameters: [],
            agreeHSMConsent: false,
            formKey: new Date().toISOString(),
            languageSelected: null,
            notification: {
                data: null,
                status: null
            }
        }

        Form.addInputTypes(types)
        momentLocalizer(moment)

        this.selectAccount = this.selectAccount.bind(this)
        this.updateHSM = this.updateHSM.bind(this)
        this.selectLanguage = this.selectLanguage.bind(this)
        this.sendHSM = this.sendHSM.bind(this)
        this.clearNotification = this.clearNotification.bind(this)
    }

    componentWillMount() {
        this._isMounted = true
        return this.prepare()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _setState(state) {
        const self = this
        return new Promise((resolve) => {
            if (self._isMounted) {
                self.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }

    clearNotification() {
        this._setState({
            notification: {
                data: null,
                status: null
            }
        })
    }

    async prepare() {
        const self = this
        let accounts = []
        let account = null
        let hsmTemplates = []
        let origins = []

        try {
            await self._setState({
                loading: true
            })

            await self.props.actions.accountsClean()
            await self.props.actions.getAccounts(self.props.params.appCodename)
            const accountsWhatsapp = []
            accounts = self.props.accounts || []
            accounts.forEach(account => {
                if (account && account.platform && account.platform.startsWith('whatsapp')) {
                    accountsWhatsapp.push(account)
                }
            })
            accounts = accountsWhatsapp
            account = accounts.length > 0 ? accounts[0] : null

            await self.props.actions.hsmTemplatesClean()
            hsmTemplates = await self.props.actions.getHSMTemplates(self.props.params.appCodename)
            hsmTemplates = self.props.hsmTemplates && self.props.hsmTemplates || []

            await self.props.actions.userGetProfile()
            const userOrigins = self.props.profile && self.props.profile.origins || []
            await self.props.actions.originsGet(self.props.params.appCodename)
            const originsAll = self.props && self.props.origins && self.props.origins.objects || []

            if (userOrigins && userOrigins.length) {
                for (const userOrigin of userOrigins) {
                    const idx = originsAll.findIndex((origin) => {
                        return origin._id === userOrigin
                    })
                    if (idx >= 0) {
                        origins.push(originsAll[idx])
                    }
                }
            } else {
                origins = originsAll
            }
        } catch (error) {
            // ...
        } finally {
            await self._setState({
                hsmTemplates,
                origins,
                account,
                accounts,
                formKey: new Date().toISOString(),
                loading: false
            })
        }
    }

    async selectAccount(e) {
        const self = this
        let account = null
        let hsmTemplates = []
        const accountId = e && e.target && e.target.value
        try {
            await self._setState({
                loading: true
            })
            const accounts = self.state.accounts || []
            account = (accountId && accounts && accounts.length) ? accounts.find(account => account._id === accountId) : null

            await self.props.actions.hsmTemplatesClean()
            hsmTemplates = await self.props.actions.getHSMTemplates(self.props.params.appCodename)
            hsmTemplates = self.props.hsmTemplates && self.props.hsmTemplates || []
        } catch (error) {
            // ...
        } finally {
            await self._setState({
                hsmTemplates,
                account,
                formKey: new Date().toISOString(),
                loading: false
            })
        }
    }

    getHSMSchema() {
        const yupSchema = {
            phone: yup.string().required(this.props.translate('hsms.fields.phone.required')).default(this.state.send && this.state.send.phone),
            template: yup.string().required(this.props.translate('hsms.fields.template.required')).default(this.state.template && this.state.send.template),
            origin: yup.string().required(this.props.translate('hsms.fields.origin.required')).default(this.state.origin),
            agreeHSMConsent: yup.boolean().required(this.props.translate('hsms.fields.agreeHSMConsent.required')).default(this.state.agreeHSMConsent)
        }
        return yup.object(yupSchema)
    }

    updateHSM(formValues, updatedFields) {
        const hsmTemplates = this.props.hsmTemplates || []
        const templateSelected = formValues.template ? hsmTemplates.find(hsmTemplate => hsmTemplate._id === formValues.template) : null
        const languageSelected = templateSelected && templateSelected.languages && templateSelected.languages.length === 1 ? templateSelected.languages[0] : null
        const parameters = this.state.send.template !== formValues.template ? [] : [...this.state.parameters]

        let agreeHSMConsent = this.state.agreeHSMConsent
        if (updatedFields && updatedFields.indexOf('agreeHSMConsent') >= 0) {
            agreeHSMConsent = !agreeHSMConsent
        }

        this._setState({
            origin: formValues.origin,
            send: formValues,
            templateSelected,
            languageSelected,
            parameters,
            agreeHSMConsent
        })
    }

    selectLanguage(e) {
        const code = e.target.value
        const { templateSelected } = this.state
        let languageSelected = null

        if (code) {
            languageSelected = templateSelected.languages.find(language => language.code === code)
        }

        this._setState({
            languageSelected
        })
    }

    updateParameter(index, e) {
        const parameters = [...this.state.parameters]
        parameters[index] = e.target.value

        this._setState({
            parameters
        })
    }

    async sendHSM() {
        const self = this
        try {
            await self._setState({ loading: true })
            const { account, send, templateSelected, languageSelected, parameters, agreeHSMConsent, origin } = self.state
            const appCodename = self.props && self.props.params && self.props.params.appCodename || ''

            if (!send || !send.phone || !account || !account._id || !account.platform|| (!appCodename && appCodename !== '')) {
                throw new Error()
            }
            const platform = account.platform

            // Busco messageUser por userId.
            const messageUserResponse = await self.props.actions.createMessageUser(
                appCodename,
                {
                    userId: send.phone,
                    accountId: account._id,
                    platform: platform
                }
            )
            const messageUser = messageUserResponse && messageUserResponse.payload && messageUserResponse.payload.data && messageUserResponse.payload.data.messageUser
            if (!messageUser || !messageUser._id) {
                throw new Error()
            }

            // Busco query
            let query
            const checkQueryActiveByUser = await self.props.actions.verifyQuery(appCodename, messageUser._id)
            if (checkQueryActiveByUser && checkQueryActiveByUser.payload && checkQueryActiveByUser.payload.data && checkQueryActiveByUser.payload.data.queryId) {
                // Hay una "query" en progreso o pendiente para ese messageUserId
                const queryResponse = await self.props.actions.getQuery(appCodename, checkQueryActiveByUser.payload.data.queryId)
                query = queryResponse && queryResponse.payload && queryResponse.payload.data
                if (query.status === 'pending') {
                    await self.props.actions.takeQuery(appCodename, query._id, origin)
                }
            } else {
                // No hay una query en progreso o pendiente para ese messageUserId
                // creo una nueva query para ese messageUserId
                const queryData = {
                    message: {},
                    messageUser,
                    account: { _id: account._id }
                }
                if (messageUser.lastMessageInByUserAt) {
                    queryData.lastNotifiedAt = messageUser.lastMessageInByUserAt
                }
                const queryResponse = await self.props.actions.newQuery(appCodename, queryData)
                query = queryResponse && queryResponse.payload && queryResponse.payload.data
                await self.props.actions.takeQuery(appCodename, query._id, origin)
            }

            if (!query || !query._id || query._id === '') {
                throw new Error()
            }
            const queryId = query && query._id
            await self.props.actions.editContext(appCodename, messageUser._id, 'customerService.active', true, queryId)
            await self.props.actions.editContext(appCodename, messageUser._id, 'customerService.id', queryId, queryId)

            const data = {
                id: send.phone,
                params: parameters,
                platform: platform,
                accountId: account._id,
                elementName: templateSelected.elementName,
                languageCode: templateSelected.languages[0].code,
                text: languageSelected.text
            }

            if (agreeHSMConsent && platform === 'whatsapp-gupshup') {
                data.agreeHSMConsent = true
            }

            await self.props.actions.sendHSM(
                appCodename,
                messageUser._id,
                data
            )

            browserHistory.push({
                pathname: `/manager/customer/${appCodename}/${queryId}`,
                search: '?status=me'
            })
        } catch (e) {
            await self._setState({
                notification: {
                    data: e && e.message || self.props.translate('hsms.error.general'),
                    status: 'danger'
                }
            })
        } finally {
            await self._setState({
                loading: false,
                agreeHSMConsent: false,
                send: {
                    phone: '',
                    template: undefined,
                    origin: ''
                },
                templateSelected: null,
                languageSelected: null,
                parameters: []
            })
        }
    }

    render() {
        const self = this
        const { loading, account, templateSelected, languageSelected, parameters, agreeHSMConsent, origins, origin } = self.state
        const accounts = self.props.accounts || []

        const hsmTemplates = (self.props.hsmTemplates || []).filter(hsmTemplate => { return (self.state && self.state.account && hsmTemplate.account === self.state.account._id) })
        const accountsWhatsapp = []
        accounts.forEach(account => {
            if (account && account.platform && account.platform.startsWith('whatsapp')) {
                accountsWhatsapp.push(account)
            }
        })

        let parts = languageSelected && languageSelected.text.split(/({{\d}})/g) || []
        let index = -1
        const text = parts.map(part => {
            if (part.match(/({{\d}})/)) {
                index++
                return (
                    <input
                        key={`param_${index}`}
                        name={`param_${index}`}
                        value={parameters[index] || ''}
                        placeholder={`{{${index + 1}}}`}
                        onChange={self.updateParameter.bind(self, index)}
                        disabled={loading}
                    />
                )
            } else {
                return part
            }
        })

        return (
            <div>
                <div className="row">
                    {(this.state.notification.data && this.state.notification.status) ? (
                        <div className="col-xs-12">
                            <br />
                            <Alert bsStyle={this.state.notification.status} onDismiss={this.clearNotification}>
                                <strong>{this.state.notification.data}</strong>
                            </Alert>
                        </div>
                    ) : ''}
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h1 className="page-header">
                            <Translate id="hsms.titles.page" />
                        </h1>
                    </div>
                </div>
                <FormGroup className="row">
                    <div className="col-xs-12">
                        <ControlLabel>
                            <Translate id="hsms.fields.platform.label" />
                        </ControlLabel>
                        <select name="account" className="form-control" type="select" onChange={self.selectAccount} disabled={loading}>
                            {
                                accountsWhatsapp.map(account => (
                                    <option key={account._id.toString()} value={account._id.toString()}>
                                        {
                                            account.name ||
                                            account.username ||
                                            (account && account.platform && platforms[account.platform] && platforms[account.platform].name) ||
                                            (platforms['unknown']
                                            && platforms['unknown'].name &&
                                            `${platforms['unknown'].name} (${account && account.platform})`)}{(account.name || account.username) && account && account.platform && account.platform.startsWith('whatsapp') ? ` (${account && account.platform && platforms[account.platform] && platforms[account.platform].shortName})` : null
                                        }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </FormGroup>
                <div>
                    <Form key={`single-hsm-${self.state.formKey}`} schema={self.getHSMSchema()} defaultValue={self.getHSMSchema().default()} onChange={self.updateHSM} onSubmit={self.sendHSM}>
                        <div className="row">
                            {(hsmTemplates && hsmTemplates.length && (
                                <div>
                                    <FormGroup className="col-xs-6">
                                        <ControlLabel>
                                            <Translate id="hsms.fields.phone.label" />
                                        </ControlLabel>
                                        <Form.Field name="phone" className="form-control" disabled={loading} />
                                        <Form.Message for="phone" />
                                    </FormGroup>
                                    <FormGroup className="col-xs-6">
                                        <ControlLabel>
                                            <Translate id="hsms.fields.template.label" />
                                        </ControlLabel>
                                        <Form.Field name="template" type="select" className="form-control" disabled={loading}>
                                            <option value={undefined}>
                                                {self.props.translate('hsms.select.template')}
                                            </option>
                                            {hsmTemplates.map(hsmTemplate => (
                                                <option key={hsmTemplate._id} value={hsmTemplate._id}>
                                                    {hsmTemplate.elementName}
                                                </option>
                                            ))}
                                        </Form.Field>
                                        <Form.Message for="template" />
                                    </FormGroup>
                                </div>
                            )) || null}

                            {(origins && origins.length && (
                                <FormGroup className="col-xs-6">
                                    <ControlLabel>
                                        <Translate id="hsms.fields.origin.label" />
                                    </ControlLabel>
                                    <Form.Field name="origin" type="select" className="form-control" disabled={loading}>
                                        <option value={undefined}>
                                            {self.props.translate('hsms.select.origin')}
                                        </option>
                                        {origins.map(origin => (
                                            <option key={origin._id} value={origin._id}>
                                                {origin.name}
                                            </option>
                                        ))}
                                    </Form.Field>
                                    <Form.Message for="origin" />
                                </FormGroup>
                            )) || null}
                            {!loading && (!hsmTemplates || (hsmTemplates && !hsmTemplates.length) && (
                                <FormGroup className="col-xs-6">
                                    <Alert bsStyle="danger">
                                        <strong>{self.props.translate('hsms.select.missing')}</strong>
                                    </Alert>
                                </FormGroup>
                            )) || null}
                            {(templateSelected && templateSelected.languages && templateSelected.languages.length > 1) ? (
                                <FormGroup className="col-xs-6">
                                    <ControlLabel>
                                        <Translate id="hsms.dataColumnNames.language" />
                                    </ControlLabel>
                                    <select name="language" value={languageSelected && languageSelected.code || undefined} className="form-control" disabled={loading} onChange={self.selectLanguage}>
                                        <option value={undefined}>
                                            {self.props.translate('hsms.select.language')}
                                        </option>
                                        {templateSelected.languages.map(language => (
                                            <option key={language.code} value={language.code}>
                                                {language.code}
                                            </option>
                                        ))}
                                    </select>
                                </FormGroup>
                            ) : ''}
                            {(text && text.length) ? (
                                <FormGroup className="col-xs-12">
                                    <Text>
                                        {text}
                                    </Text>
                                </FormGroup>
                            ) : ''}
                            {(account && account.platform === 'whatsapp-gupshup') && (
                                <div className="col-xs-12">
                                    <div className="checkbox">
                                        <ControlLabel>
                                            <Form.Field name="agreeHSMConsent" type="checkbox" checked={!!agreeHSMConsent} /><Translate id="hsms.fields.agreeHSMConsent.label" />
                                        </ControlLabel>
                                    </div>
                                    <Form.Message for="agreeHSMConsent" />
                                </div>
                            ) || null}
                            <div className="col-xs-12">
                                <br />
                                <ButtonToolbar className="pull-right">
                                    {(hsmTemplates && hsmTemplates.length && (
                                        <Form.Button type="submit" className="btn btn-primary" disabled={loading || !languageSelected || (!origin && (!origins || origins.length > 0)) || !(self.state.send && self.state.send.phone && self.state.send.phone.length) || (account.platform === 'whatsapp-gupshup' && !agreeHSMConsent)}>
                                            <Translate id="buttons.send" />
                                        </Form.Button>
                                    )) || null}
                                </ButtonToolbar>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        routing: state.routing,
        accounts: state.accounts,
        hsmTemplates: state.hsmTemplates,
        profile: state.profile,
        origins: state.origins
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(HSMs))
