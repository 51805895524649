import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Spinner from 'react-spinkit'
import { Scrollbars } from 'react-custom-scrollbars'
import * as platforms from '../../../../components/common/Platforms'

import {
    Container,
    Separator,
    ToBottom,
    NotFound
} from './styles'

import {
    Button
} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MessageContainer from '../MessageContainer'
import PendingMessage from '../PendingMessage'

class Messages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showSpinner: false,
            showToBottom: false
        }

        this.verifyScrollPosition = this.verifyScrollPosition.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
        this.checkScrollToBottom = this.checkScrollToBottom.bind(this)
        this.getValues = this.getValues.bind(this)
        this.scrollTop = this.scrollTop.bind(this)
        this.loadOlderMessages = this.loadOlderMessages.bind(this)
        this.reloadMessages = this.reloadMessages.bind(this)

        this.userColors = ['#ED8A9D', '#31C5DD', '#F4D345', '#F95E4E', '#27CCC3', '#D4D6D6', '#422656', '#11384C']
        this.assigned = {}
    }

    componentWillUnmount() {
        if (this.refreshDataTimer) {
            clearInterval(this.refreshDataTimer)
            this.refreshDataTimer = null
        }
    }

    verifyScrollPosition(event) {
        const self = this
        let promise = Promise.resolve()
        const state = {}

        if (self.chat) {
            const values = self.chat.getValues()

            if (values.top <= 0.9 && values.scrollHeight > values.clientHeight && !self.state.showToBottom) {
                state.showToBottom = true
            } else if (values.top > 0.9 && self.state.showToBottom) {
                state.showToBottom = false
            }

            if (!this.props.stopInfiniteLoadTop && self.props.onInfiniteLoadTop && (values.top === 0 || !event)) {
                promise = promise.then(() => {
                    return self.props.onInfiniteLoadTop()
                }).then(() => {
                    self.setState({
                        showSpinnerTop: false
                    }, () => {
                        const newValues = self.chat.getValues()
                        self.chat.scrollTop(newValues.scrollHeight - values.scrollHeight)
                    })
                })

                state.showSpinnerTop = true
            } else if (!this.props.stopInfiniteLoadBottom && self.props.onInfiniteLoadBottom && values.top === 1) {
                promise = promise.then(() => {
                    return self.props.onInfiniteLoadBottom()
                }).then(() => {
                    self.setState({
                        showSpinnerBottom: false
                    })
                })

                state.showSpinnerBottom = true
            }
        } else if (!event && self.props.onInfiniteLoadTop) {
            return self.props.onInfiniteLoadTop()
        }

        self.setState(state, () => promise)
    }

    scrollToBottom() {
        if (this.chat) {
            this.chat.scrollToBottom()
        }
    }

    checkScrollToBottom() {
        if (this.chat) {
            const values = this.chat.getValues()
            if (values && (values.top >= 0.9 || (values.scrollHeight <= values.clientHeight))) {
                this.chat.scrollToBottom()
            }
        }
    }

    getValues() {
        if (this.chat) {
            return this.chat.getValues()
        }
    }

    scrollTop(values) {
        const self = this
        if (self.chat) {
            self.chat.scrollTop(values.scrollTop)
        }
    }

    loadOlderMessages() {
        if (this.props.loadOlderMessages) {
            this.props.loadOlderMessages()
        }
    }

    reloadMessages() {
        if (this.props.reloadMessages) {
            this.props.reloadMessages()
        }
    }

    showMessages(data) {
        const { app, query, speech, hasOlderMessagesToLoad } = this.props

        let from = ''
        let previousAutor = ''
        const messages = []

        if (data && data.length) {
            data.forEach((message, index) => {
                const newAutor = message.operatorData && message.operatorData.operatorId
                if (newAutor) {
                    if (!this.assigned[message.operatorData.operatorId]) {
                        this.assigned[message.operatorData.operatorId] = this.userColors.shift()
                    }
                    message.userColor = this.assigned[message.operatorData.operatorId]
                }
                if (!from) {
                    from = message.from
                    previousAutor = message.operatorData && message.operatorData.operatorId
                } else if (from !== message.from || (newAutor && previousAutor !== newAutor)) {
                    messages[index - 1].lastGroupMessage = true
                    if (from !== message.from) {
                        from = message.from
                    }
                    if (previousAutor !== newAutor) {
                        message.firstGroupMessage = true
                        previousAutor = newAutor
                    }
                }
                messages.push(message)
            })
        }

        if (messages.length >= 1) {
            messages[messages.length - 1].lastGroupMessage = true
        }

        const platform = query && query.messageUser && query.messageUser.platform
        const platformSettings = platforms[platform] || platforms['unknown']
        const color = platformSettings.color

        let profilePicture = query.messageUser.profilePictureURL
        if (profilePicture && !profilePicture.startsWith('http')) {
            profilePicture = `${(app && app.chattonic && app.chattonic.url) ? app.chattonic.url : ''}${profilePicture}`
        } else if (!profilePicture) {
            profilePicture = '/manager/static/images/user.png'
        }

        const toReturn = []
        const typesAllowed = ['text', 'image', 'video', 'voice', 'document', 'location', 'hsm', 'formData', 'contact']
        let showedStartOfQuery = false

        if (messages) {
            messages.forEach(message => {
                if (typesAllowed.indexOf(message.type) >= 0) {
                    toReturn.push(
                        <MessageContainer
                            key={`message_${message._id}`}
                            data={message}
                            logo={profilePicture}
                            color={color}
                            checkScrollToBottom={this.checkScrollToBottom}
                            timezone={(app && app.timezone) ? app.timezone : ''}
                            chattonicURL={(app && app.chattonic && app.chattonic.url) ? app.chattonic.url : ''}
                            speech={speech}
                            queryMessageId={query && query.message && query.message.id && `${query.message.id}`}
                            queryStatus={query && query.status || ''}
                            deleteMessage={this.props.deleteMessage}
                        />
                    )

                    if (!showedStartOfQuery && message.createdAt >= query.createdAt) {
                        showedStartOfQuery = true
                        toReturn.push(
                            <Separator key={`start_of_query`}>
                                <Translate id="query.start" />
                            </Separator>
                        )
                    }
                }
            })
        }

        if (['resolved', 'failed'].indexOf(query.status) >= 0) {
            toReturn.push(
                <Separator key={`end_of_query`}>
                    <Translate id="query.end" />
                </Separator>
            )
        }

        if ((['resolved', 'failed'].indexOf(query.status) < 0 || !showedStartOfQuery) && hasOlderMessagesToLoad) {
            toReturn.unshift(
                <Separator key={`start_load_older`}>
                    <Button bsStyle="default" onClick={ this.loadOlderMessages }>
                        <FontAwesomeIcon icon="chevron-up" />&nbsp;<Translate id="query.loadOlderMessages" />
                    </Button>
                </Separator>
            )
        } else {
            toReturn.unshift(
                <Separator key={`start_load_older_forbidden`}>
                    <Translate id="query.olderMessagesNotAccesible" />
                </Separator>
            )
        }

        return toReturn
    }

    render() {
        let messages = []
        if (!(this.props.messages && this.props.user && this.props.query)) {
            return (
                <Container>
                    <Spinner name="circle" />
                </Container>
            )
        } else if (!this.props.messages.length) {
            return (
                <Container>
                    <NotFound>
                        <Translate id="conversation.messagesNotFound" />
                        <br />
                        <br />
                        <Button bsStyle="default" onClick={ this.reloadMessages }>
                            <FontAwesomeIcon icon="redo" />&nbsp;<Translate id="query.reloadMessages" />
                        </Button>
                    </NotFound>
                </Container>
            )
        } else {
            messages = this.props.messages
        }

        return (
            <Container>
                <Scrollbars
                    ref={(c) => { this.chat = c }}
                    className="chat"
                >
                    {(this.props.showSpinner || this.state.showSpinner) ? (
                        <Container>
                            <Spinner name="circle" />
                        </Container>
                    ) : ''}
                    {this.showMessages(messages)}
                    {this.props.messagesToSend.map((data, index) => <PendingMessage key={`pendingMessage_${index}`} {...data} />)}
                </Scrollbars>
                {(this.state.showToBottom) ? (
                    <ToBottom onClick={this.scrollToBottom}>
                        <FontAwesomeIcon icon="chevron-down" />
                    </ToBottom>
                ) : ''}
            </Container>
        )
    }
}

Messages.propTypes = {
    user: PropTypes.object,
    profile: PropTypes.object,
    query: PropTypes.object,
    app: PropTypes.object,
    messages: PropTypes.array,
    messagesToSend: PropTypes.array,
    showSpinner: PropTypes.bool,
    openModal: PropTypes.func,
    onInfiniteLoadTop: PropTypes.func,
    onInfiniteLoadBottom: PropTypes.func,
    stopInfiniteLoadTop: PropTypes.bool,
    stopInfiniteLoadBottom: PropTypes.bool,
    deleteMessage: PropTypes.func,
    reloadMessages: PropTypes.func,
    loadOlderMessages: PropTypes.func,
    hasOlderMessagesToLoad: PropTypes.bool,
    speech: PropTypes.func
}

export default Messages
